<template>
  <v-text-field
    outlined
    label="输入高度、交易哈希、地址"
    append-icon="mdi-magnify"
    background-color="#fff"
    v-model="search"
    :rules="[rule]"
    @click:append="onSearch"
    @keydown.enter="onSearch"
  />
</template>

<script>
import { Contract } from '@/api';
import { valid } from '@/utils';

export default {
  name: 'Search',
  data: () => ({
    search: '',
    rule: (_) =>
      !!!_ ||
      valid.isNumber(_) ||
      valid.isAddress(_) ||
      valid.isTxHash(_) ||
      '无效的输入值',
  }),
  methods: {
    onSearch() {
      const _ = this.search;
      if (valid.isNumber(_)) {
        this.$router.push(`/blocks/${_}`);
        return;
      }
      if (valid.isAddress(_)) {
        this.$router.push(`/address/${_}`);
        return;
      }
      if (valid.isTxHash(_)) {
        this.$router.push(`/transactions/${_}`);
        return;
      }
    },
  },
};
</script>
