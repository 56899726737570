var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_c('span',[_vm._v("交易")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","to":"/transactions"}},[_vm._v(" 全部 "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"disable-sort":"","hide-default-footer":"","headers":_vm.table.headers,"items":_vm.table.items},scopedSlots:_vm._u([{key:"item.tx_hash",fn:function(ref){
var item = ref.item;
return [_c('RippleLink',{attrs:{"to":("/transactions/" + (item.tx_hash))}},[_c('Ellipsis',{attrs:{"width":_vm.width}},[_vm._v(" "+_vm._s(item.tx_hash)+" ")])],1)]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_c('RippleLink',{attrs:{"to":("/address/" + (item.from))}},[_c('Ellipsis',{attrs:{"width":_vm.width}},[_vm._v(" "+_vm._s(item.from)+" ")])],1)]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('RippleLink',{attrs:{"to":("/address/" + (item.to))}},[_c('Ellipsis',{attrs:{"width":_vm.width}},[_vm._v(" "+_vm._s(item.to)+" ")])],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTransactionName(item))+" ")]}},{key:"item.block_number",fn:function(ref){
var item = ref.item;
return [_c('RippleLink',{attrs:{"to":("/blocks/" + (item.block_number))}},[_vm._v(" "+_vm._s(item.block_number)+" ")])]}},{key:"item.pended_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.YMDHms(item.pended_at))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }