<template>
  <v-card elevation="2">
    <v-card-title>
      <span>区块</span>
      <v-spacer />
      <v-btn text to="/blocks">
        全部
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn></v-card-title
    >
    <v-data-table
      disable-sort
      hide-default-footer
      :headers="table.headers"
      :items="table.items"
      class="elevation-1"
    >
      <template v-slot:[`item.number`]="{ item }">
        <RippleLink :to="`/blocks/${item.number}`">
          {{ item.number }}
        </RippleLink>
      </template>
      <template v-slot:[`item.time`]="{ item }">
        {{ YMDHms(item.time) }}
      </template>
      <template v-slot:[`item.transaction_count`]="{ item }">
        <RippleLink :to="{ path: '/transactions', query: { hash: item.node } }">
          {{ item.transaction_count }}
        </RippleLink>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { RippleLink } from '@/components';
import { Block } from '@/api';
import { YMDHms } from '@/utils';

export default {
  name: 'Blocks',
  components: { RippleLink },
  data: () => ({
    table: {
      headers: [
        { text: '块高', value: 'number' },
        { text: '出块时间', value: 'time' },
        { text: '交易数量', value: 'transaction_count' },
      ],
      items: [],
    },
  }),
  mounted() {
    this.getBlocks();
  },
  methods: {
    YMDHms,
    getBlocks() {
      Block.describeBlocks(7, 0, undefined, undefined).then((res) => {
        this.table.items = res.blocks;
      });
    },
  },
};
</script>
