<template>
  <v-card elevation="2">
    <v-card-title>
      <span>交易量监控</span>
      <v-spacer />
      <v-btn text @click="getChartData(1)">1天</v-btn>
      <v-btn text @click="getChartData(7)">7天</v-btn>
      <v-btn text @click="getChartData(15)">15天</v-btn>
      <v-btn text @click="getChartData(30)">30天</v-btn>
    </v-card-title>
    <ve-histogram
      :settings="settings"
      :data="chartData"
      :legend-visible="false"
      :colors="colors"
      :loading="loading"
      :data-empty="dataEmpty"
    ></ve-histogram>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';
import { Transaction } from '@/api';
import 'v-charts/lib/style.css';

export default {
  name: 'Chart',
  data: () => ({
    settings: { labelMap: { date: '日期', count: '日交易量' } },
    loading: false,
    dataEmpty: false,
    colors: ['#2196F3'],
    chartData: { columns: ['date', 'count'], rows: [] },
  }),
  mounted() {
    this.getChartData(30);
  },
  methods: {
    getChartData(day) {
      Transaction.getTradingRecordChart(
        dayjs().subtract(day - 1, 'day').format('YYYYMMDD'),
        dayjs().format('YYYYMMDD')
      ).then((res) => {
        this.chartData.rows = res.records;
      });
    },
  },
};
</script>
