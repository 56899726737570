<template>
  <v-card elevation="2">
    <v-card-title>
      <span>交易</span>
      <v-spacer />
      <v-btn text to="/transactions">
        全部
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-title>
    <v-data-table
      disable-sort
      hide-default-footer
      :headers="table.headers"
      :items="table.items"
      class="elevation-1"
    >
      <template v-slot:[`item.tx_hash`]="{ item }">
        <RippleLink :to="`/transactions/${item.tx_hash}`">
          <Ellipsis :width="width">
            {{ item.tx_hash }}
          </Ellipsis>
        </RippleLink>
      </template>
      <template v-slot:[`item.from`]="{ item }">
        <RippleLink :to="`/address/${item.from}`">
          <Ellipsis :width="width">
            {{ item.from }}
          </Ellipsis>
        </RippleLink>
      </template>
      <template v-slot:[`item.to`]="{ item }">
        <RippleLink :to="`/address/${item.to}`">
          <Ellipsis :width="width">
            {{ item.to }}
          </Ellipsis>
        </RippleLink>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ getTransactionName(item) }}
      </template>
      <template v-slot:[`item.block_number`]="{ item }">
        <RippleLink :to="`/blocks/${item.block_number}`">
          {{ item.block_number }}
        </RippleLink>
      </template>
      <template v-slot:[`item.pended_at`]="{ item }">
        {{ YMDHms(item.pended_at) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { RippleLink, Ellipsis } from '@/components';
import { Transaction } from '@/api';
import { YMDHms, getTransactionName } from '@/utils';

export default {
  name: 'Transactions',
  components: { RippleLink, Ellipsis },
  data: () => ({
    width: '240px',
    table: {
      headers: [
        { text: '交易哈希', value: 'tx_hash' },
        { text: '交易发起者', value: 'from' },
        { text: '交易接收者', value: 'to' },
        { text: '交易类型', value: 'type' },
        { text: '区块高度', value: 'block_number' },
        { text: '交易时间', value: 'pended_at' },
      ],
      items: [],
    },
  }),
  mounted() {
    this.getTransactions();
  },
  methods: {
    YMDHms,
    getTransactionName,
    getTransactions() {
      Transaction.describeTradingRecords({ limit: 10, offset: 0 }).then(
        (res) => {
          this.table.items = res.trading_records;
        }
      );
    },
  },
};
</script>
