<template>
  <v-card elevation="2" min-height="148px">
    <v-card-title>{{ title }}</v-card-title>
    <v-card-actions>
      <v-spacer />
      <span class="font-weight-black text-h4 blue--text">
        <RippleLink :to="`${to}`">
          {{ value }}
        </RippleLink>
        </span>
    </v-card-actions>
  </v-card>
</template>

<script>
import { RippleLink } from '@/components';
export default {
  name: 'Statistic',
  components: { RippleLink },
  props: ['title', 'value', 'to'],
};
</script>
