<template>
  <v-container>
    <v-row>
      <v-col>
        <Search />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="6" cols="12">
        <v-row>
          <v-col cols="6" v-for="_ in statistics" :key="_.title">
            <Statistic :title="_.title" :value="_.value" :to="_.to" />
          </v-col>
        </v-row>
      </v-col>
      <v-col sm="6" cols="12">
        <Blocks />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Chart />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Transactions />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Search from './components/Search.vue';
import Statistic from './components/Statistic.vue';
import Blocks from './components/Blocks.vue';
import Chart from './components/Chart.vue';
import Transactions from './components/Transactions.vue';
import { Block, Transaction, Contract } from '@/api';

export default {
  name: 'Home',
  components: {
    Search,
    Statistic,
    Blocks,
    Chart,
    Transactions,
  },
  data: () => ({
    statistics: [
      { title: '区块数量', value: 0, to: '/blocks' },
      { title: '交易数量', value: 0, to: '/transactions' },
      { title: '积分数量', value: 0, to: '/address?type=积分' },
      { title: '卡券数量', value: 0, to: '/address?type=卡券' },
      { title: '数字文创', value: 0, to: '/address?type=数字文创' },
    ],
  }),
  mounted() {
    Block.describeBlocks(0, 0, undefined, undefined).then(
      (res) => (this.statistics[0].value = res.total_count || 0)
    );
    Transaction.describeTradingRecords({ limit: 0, offset: 0 }).then(
      (res) => (this.statistics[1].value = res.total_count || 0)
    );
    Contract.describeContracts(0, 0, undefined, undefined, [
      'GRC20',
    ]).then((res) => (this.statistics[2].value = res.total_count || 0));
    Contract.describeContracts(0, 0, undefined, undefined, [
      'COUPON',
    ]).then((res) => (this.statistics[3].value = res.total_count || 0));
    Contract.describeContracts(0, 0, undefined, undefined, [
      'ARTWORK', 'DIGITALIP',
    ]).then((res) => (this.statistics[4].value = res.total_count || 0));
  },
  methods: {},
};
</script>
